/* eslint-disable no-console */
import { Component as ReactComponent, forwardRef } from 'react';
import isEqual from 'lodash/isEqual';
import { isShowDebugLog } from 'root/constants/dev';

export function withComponentHandler(Component, name) {
  class WithComponentHandler extends ReactComponent {
    constructor() {
      super();

      this.Component = Component;

      this.state = {
        hasError: false,
      };
    }

    shouldComponentUpdate(nextProps, nextState) {
      return (
        !isEqual(nextProps, this.props)
        || !isEqual(nextState, this.state)
      );
    }

    componentDidCatch(error, info) {
      console.error('error: ', error);
      console.error('errorInfo: ', info);

      this.setState({
        hasError: true,
      });
    }

    render() {
      const {
        hasError,
      } = this.state;

      const {
        innerRef,
        ...props
      } = this.props;

      if (hasError) { return null; }

      const isShowDebug = isShowDebugLog && name;
      if (isShowDebug) { console.log('Rendered: ', name); }

      return (
        <this.Component
          ref={innerRef || null}
          {...props}
        />
      );
    }
  }

  return forwardRef((props, ref) => <WithComponentHandler innerRef={ref} {...props} />);
}

export default withComponentHandler;
