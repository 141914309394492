import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import withComponentHandler from 'root/components/withComponentHandler';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { isDevelopment } from 'root/constants/dev';
import { useEffect, useState } from 'react';

const CACHE_TIME = 5 * 60 * 1000; // 5 minutes
const STALE_TIME = 5 * 60 * 1000; // 5 minutes
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
      retry: false,
    },
  },
});

function App() {
  const [Content, setContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const [
        { value: { default: dayjs } },
        { value: { default: DayjsUtc } },
        { value: { default: dayjsTimezone } },
        { value: { default: isToday } },
      ] = await Promise.allSettled([
        import('dayjs'),
        import('dayjs/plugin/utc'),
        import('dayjs/plugin/timezone'),
        import('dayjs/plugin/isToday'),
      ]);

      dayjs.extend(DayjsUtc);
      dayjs.extend(dayjsTimezone);
      dayjs.extend(isToday);

      const { default: ContentComponent } = await import('./Content');

      setContent(ContentComponent);
    };

    fetchData();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        {Content && <Content />}
        {isDevelopment && (
          <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
        )}
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

export default withComponentHandler(App, 'App');
